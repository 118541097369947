<template>
  <div class="interact gray-bcg-color">
    <c-banner :bannerData="bannerData"
              :src="src"></c-banner>
    <c-menu :menuData="menuData"></c-menu>
    <a name="advantage"
       class="anchor-replace"></a>
    <div class="v-advantage">
      <c-title :titleArr="advantageTitle"></c-title>
      <product-advantage :advantageData="advantageData1"
                         :isInteract="true"></product-advantage>
    </div>
    <a name="solution"
       class="anchor-replace"></a>
    <div class="v-solution white_bcg-color">
      <c-title :titleArr="solutionTitle"></c-title>
      <c-solution :src="solutionSrc"></c-solution>
    </div>
    <a name="function"
       class="anchor-replace"></a>
    <div class="anchorPoint">
      <c-title :titleArr="functionTitle"></c-title>
      <product-fun :productFunData="productFunData"
                   serviceType="interact"></product-fun>
    </div>
    <a name="price"
       class="anchor-replace"></a>
    <div>
      <c-title :titleArr="priceTitle"></c-title>
      <c-price :priceData="priceData">
        <div class="price-content">
          <el-image class="price-img"
                    :src="require('../../../assets/images/interact/price.png')"
                    alt=""
                    lazy></el-image>
        </div>
      </c-price>
    </div>
    <a name="doc"
       class="anchor-replace"></a>
    <div class="document">
      <c-title class="doc"
               :titleArr="documentTitle"
               :isDocTitle="true"></c-title>
      <div class="doc-content c-width">
        <a :href="`${docSrc}docs/show/453.html`"
           target="_blank">产品文档</a>
        <a :href="`${docSrc}docs/show/459.html`"
           target="_blank">快速开始</a>
        <a :href="`${docSrc}docs/show/483.html`"
           target="_blank">API</a>
        <a :href="`${docSrc}docs/show/554.html`"
           target="_blank">SDK</a>
        <a :href="`${docSrc}docs/show/557.html`"
           target="_blank">常见问题</a>
      </div>
    </div>
    <a name="recommand"
       class="anchor-replace"></a>
    <div>
      <c-title :titleArr="recommendTitle"></c-title>
      <c-recommend curService="interact"></c-recommend>
    </div>
  </div>
</template>

<script>
import {
  cRecommend,
  cBanner,
  cPrice,
  cMenu,
  cSolution,
  productFun,
  productAdvantage
} from '../components'
import { cTitle } from '@/components'
export default {
  components: {
    cRecommend,
    cBanner,
    cPrice,
    cMenu,
    cSolution,
    productFun,
    productAdvantage,
    cTitle
  },
  data () {
    return {
      advantageTitle: ['产品优势'],
      docSrc: window.urlConfig.docUrl,
      functionTitle: ['产品功能'],
      solutionTitle: ['端到端的解决方案'],
      priceTitle: ['产品价格'],
      documentTitle: ['产品文档'],
      recommendTitle: ['相关推荐'],
      isShowOneGroup: true,
      bannerData: {
        title: '音视频互动',
        textData: [
          '多人视频实时互动',
          '多端SDK快捷接入',
          '快捷构建多人连麦互动直播间'
        ],
        href2: `${window.urlConfig.docUrl}docs/show/552.html`
      },
      src: require('../../../assets/images/interact/interact-banner.png'),
      solutionSrc: require('../../../assets/images/interact/solution@2x.png'),
      advantageData1: [
        {
          flag: true,
          src: require('../../../assets/images/interact/advantage2@2x.png')
        },
        {
          flag: true,
          src: require('../../../assets/images/interact/advantage4@2x.png')
        },
        {
          flag: false,
          src: require('../../../assets/images/interact/advantage3@2x.png')
        },
        {
          flag: false,
          src: require('../../../assets/images/interact/advantage1@2x.png')
        }
      ],
      priceData: [
        {
          bcg: '#65A4FE',
          title: '流量计费',
          text: '1.5元／GB',
          detailUrl: `${window.urlConfig.docUrl}docs/show/455.html`
        }
      ],
      menuData: [
        {
          src: require('../../../assets/images/subnav1@2x.png'),
          id: '#advantage',
          text: '产品优势'
        },
        {
          src: require('../../../assets/images/subnav2@2x.png'),
          id: '#solution',
          text: '解决方案'
        },
        {
          src: require('../../../assets/images/subnav3@2x.png'),
          id: '#function',
          text: '产品功能'
        },
        {
          src: require('../../../assets/images/subnav4@2x.png'),
          id: '#price',
          text: '产品价格'
        },
        {
          src: require('../../../assets/images/subnav5@2x.png'),
          id: '#doc',
          text: '产品文档'
        },
        {
          src: require('../../../assets/images/subnav6@2x.png'),
          id: '#recommand',
          text: '相关推荐'
        }
      ],
      productFunData: [
        {
          className: 'interactPlat',
          title: '跨平台互动',
          desc:
            '支持Android、iOS、WEB主流平台开播、观看及互动；支持一对多，多对多的互动直播'
        },
        {
          className: 'interactLetter',
          title: '音视频通信',
          desc: '支持音视频通信，提供高质量的音视频通信效果'
        },
        {
          className: 'interactShare',
          title: '桌面分享',
          desc: '支持jssdk屏幕共享'
        },
        {
          className: 'interactRecord',
          title: '录制',
          desc:
            '支持混流视频的录制，将混流录制下来的视频存储在点播服务中，可在点播服务中对视频进行管理'
        },
        {
          className: 'interactLive',
          title: '旁路直播',
          desc: '支持直播混流，并将混流画面推送至指定的直播房间'
        },
        {
          className: 'interactjianhuang',
          title: '智能鉴黄',
          desc:
            '支持对涉黄行为进行高效自动识别，可根据用户管控尺度，灵活调整策略'
        },
        {
          className: 'interactPs',
          title: '美颜滤镜',
          desc: '提供移动端SDK美颜滤镜功能，支持移动sdk上的美白，磨皮'
        }
      ]
    }
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
.interact {
  .on {
    position: absolute;
    top: 0;
    opacity: 0;
  }
  .v-advantage {
    margin: 0px 0 62px;
  }
  .price-img {
    width: 549px;
    margin: 30px 55px 50px;
  }
  .doc {
    padding-top: 44px;
  }
  .doc-content {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    margin-top: 53px;
    a {
      color: #bfcddd;
      font-size: 18px;
      text-align: center;
      line-height: 32px;
      font-weight: 500;

      &:not(:last-child) {
        border-right: 1px solid #fff;
      }

      &:hover {
        color: #fff;
      }
    }
  }
}
</style>
